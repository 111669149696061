.footer {
    width: 100%;
    padding: 6rem 0;
    background-color: rgba(19, 19, 19, 0.8);
  }
  
  .footer-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
  
  .left,
  .middle,
  .right {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
  
  .social {
    margin-top: 1rem;
  }
  
  h4 {
    font-size: 1.2rem;
    padding-bottom: 0.7rem;
  }
  
  p {
    font-size: 1.2rem;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  @media screen and (max-width: 640px) {
    .footer-container {
      grid-template-columns: 1fr;
    }
  }
  
  .bottom-section {
    text-align: center;
    margin-top: 2rem;
    color: #fff;
  }
  
  /* Added styles for the Contact Information */
  .middle {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
  
  .contact-list {
    list-style: none;
    padding: 0;
    color: #fff;
  }
  
  .contact-list li {
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
  }
  
  .contact-list li svg {
    margin-right: 0.5rem;
  }
  
  /* Added styles for Social Links */
  .right p {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  
  @media screen and (max-width: 640px) {
    .footer-container {
      grid-template-columns: 1fr;
    }
  }
  
  .bottom-section {
    text-align: center;
    margin-top: 2rem;
    color: #fff;
  }
  